.settings-outer-frame {
  position: absolute;
  top: 48px;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgb(248, 249, 250);
  padding-top: 20px;  
}

.settings-inner-frame {
  width: 800px;
  margin: 0 auto;
  background-color: #fff;
  border: 1px solid lightgray;
  padding: 15px;
}

.settings-section {
  margin-top: 20px;
  margin-bottom: 20px;
}

.settings-section-header {
  line-height: 1.5;
  border-bottom: 2px solid lightgray;
  margin-bottom: 10px;
}

img#group-icon {
  display: block;
  width: 128px;
}

button#change-group-icon {
  width: 100px;
  margin: 4px auto;
}

.group-settings-left {
  min-width: 150px;
  margin-right: 6px;
}

select#select-applet {
  width:90%;
}

.applet-commands button {
  width: 150px;
  margin-right: 8px;
  margin-bottom: 8px;
  margin-top: 8px;
}

button#new-applet {
  margin-top: 4px;
}

#applet-name {
  font-weight: bold;
}