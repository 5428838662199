/*
 * App styles
 */

.applet-container {
  position: fixed;
  top: 0px;
  left: 256px;
  bottom: 0px;
  right: 0px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  overflow-y: hidden;
  z-index: 1000;
}

.applet-command-bar {
  height: 54px;
}

.border-bottom {
  border-bottom: 1px solid #eaeaea;
}
