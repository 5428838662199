/*
 * Notebook styles
 */

.notebook-page-list {
  width: 350px;
  height: 100%;
  padding: 0 6px 6px 6px;
  border-left: 1px solid #eaeaea;
  background-color: #fbfbfc;
}

.notebook-page-detail {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/*
 * Items in the list
 */

.notebook-page-list-header {
  margin-top: 6px;
  font-size: 1.5em;
  font-weight: bold;
}

.notebook-page-list-item {
  padding: 4px 8px 4px 8px;
  cursor: pointer;
  font-weight: bold;
}

.notebook-page-list-item:hover {
  background-color: #eaeaea;
}

.notebook-page-list-item-selected {
  background-color: #cccccc;
}

.notebook-page-list-item-dragging {
  background-color: #eeeeee;
}

/*
 * Item detail styles
 */

.notebook-page-detail-container {
  margin-top: 12px;
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.notebook-page-detail-title {
  padding-bottom: 1px;
  font-size: 1.5em;
  font-weight: bold;
  flex: 1;
}

div.notebook-page-detail-title {
  border: 1px solid transparent;
  padding-top: 0px;
  padding-left: 1px;
}

input.notebook-page-detail-title {
  padding-top: 1px;
}

.notebook-page-detail-body {
  font-size: 1em;
  padding: 2px;
}

div.notebook-page-detail-body {
  white-space: pre-wrap;
  border: 1px solid transparent;
}

textarea.notebook-page-detail-body {
  flex: 1;
}

.notebook-page-button-container {
  padding-top: 4px;
  text-align: right;
}
