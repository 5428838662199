/*
 * Task styles
 */

.task-list {
  width: auto;
  flex: 7;
}

.task-detail {
  flex: 3;
  background-color: #fbfbfc;
  border-left: 1px solid #eaeaea;
}

/*
 * Items in the list.
 */
.task-container {
  padding: 4px 8px 4px 8px;
  margin-bottom: 4px;
}

.task-selected {
  background-color: #eaeaea;
}

.task-header {
  font-weight: bold;
}

.task-body {
  overflow: hidden;
  position: relative;
  box-sizing: content-box;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.task-body:before {
  content: '';
  float: left;
  width: 5px;
}

/*
 * Item detail styles
 */

.task-detail-container {
  padding-left: 14px;
  padding-right: 14px;
  display: flex;
  flex-direction: column;
  min-height: 90%;
}

.task-detail input[readonly] {
  border: 0;
  background-color: transparent;
}

.task-detail textarea[readonly] {
  border: 0;
  background-color: transparent;
}

.task-detail-date {
  margin-top: 15px;
  font-size: 1.5em;
  font-weight: bold;
}

.task-detail-time {
  font-size: 0.9em;
}

.task-detail-body {
  white-space: pre-wrap;
  font-size: 1em;
  margin-top: 16px;
}
