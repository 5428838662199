/*
 * Blog styles
 */

.blog-post-list {
  width: 350px;
  height: 100%;
  padding: 0 6px 6px 6px;
  border-right: 1px solid #EAEAEA;
  overflow-y: auto;
}

.blog-post-detail {
  overflow: hidden;
  height: 100%;
  padding: 0 6px 6px 6px;
  flex: 1;
  display: flex;
  flex-direction: column;

}

/*
 * Items in the list.  Ellipsis styles from 
 * http://dev.mobify.com/blog/multiline-ellipsis-in-pure-css/
 */

.blog-post-container {
  padding: 4px 8px 4px 8px;
}

.blog-post-selected {
  background-color: #EAEAEA;
}

.blog-post-header {
  font-weight: bold;
}

.blog-post-body {
  max-height: 80px;
  overflow: hidden;
  position: relative;
  box-sizing: content-box;
}

.blog-post-body:before {
  content: "";
  float: left;
  width: 5px;
  height: 80px;
}

.blog-post-body>*:first-child {
  float: right;
  width: 100%;
  margin-left: -5px;
}

.blog-post-body:after {
  content: "\02026";
  box-sizing: content-box;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  float: right;
  position: relative;
  top: -23px;
  left: 100%;
  width: 1.3em;
  margin-left: -1.3em;
  padding-right: 5px;
  text-align: right;
  background-size: 100% 100%;
  /* 1706x1 image, gradient for IE9. Transparent at 0% -> white at 15% -> white at 100%.*/
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABqoAAAABCAMAAACRxg/eAAAABGdBTUEAALGPC/xhBQAAAwBQTFRF////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////AAAA////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////wDWRdwAAAP90Uk5Tfet1sDrNWZIeSRDcocCFLWj1CbhSfuMl1EGoYJkYM8Zui/rwBk15FefYKrxGtKydZQzfIdDJNj1VpFyVcY6BBBwxw2yI/Pfy7RInQ09ie5B0eIMDD+kb5eEk2tbSMMs5QL66tkyyrqpYpl+fm5drAf0H+AoN8+4TFhkf3SIoKwDONDfHO8Q+wURHSlBTVlqiXWNmaZNvcox2iYZ/fAL+9vsFCPkL9A4R8e/sFOjqFxrm5B3i4CDeIybb2SnX1SzT0S/PMsw1yjjIxTzCP79CvbtFuUi3tUuzTrGvUa1Uq6lXp6Vbo16gYZ6cZJpnmJZqlG2RcI9zjYp6d4eChIAu7+D8pQAAASFJREFUOMtjqK+fG2y/UKVwkVDxEsOSZSFcyz3LV6tXrJOtXB/msImjaqtxzfZI8R1etbu16vbx/zsY63hYrvGoWdPxeJaWU+ZtZxIkz3l3XNTpvMzZdS1F9YZP922RnrsWvQ8y2PoeW014miX93HfiK4NJbwQmv89z/qgw9bPNtK8BTDN+Bqr98JvDIDr7r93voD98s365zPyuOP2b7Rf/T+xTPli/y30r8zrnpf6L7Gc8/U8sH2U+lLqffk/vTtot5pup13WvJl8RvpR0Qft84lne9tNOrSflm0+YHos7wtpwyORAzH6JvdF7NHdF7eSu3ma0JWKz2MbwDRprQtcyrvIoW6m8wr10qeB8t6LFSgtcC+b9HwWjYBSMglEw2AEA3ckkQdBniOMAAAAASUVORK5CYII=);
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(white), color-stop(15%, white));
  background: -moz-linear-gradient(to right, rgba(255, 255, 255, 0), white 15%, white);
  background: -o-linear-gradient(to right, rgba(255, 255, 255, 0), white 15%, white);
  background: -ms-linear-gradient(to right, rgba(255, 255, 255, 0), white 15%, white);
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white 15%, white);
}

.blog-post-selected .blog-post-body:after {
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(#EAEAEA), color-stop(15%, #EAEAEA));
  background: -moz-linear-gradient(to right, rgba(255, 255, 255, 0), #EAEAEA 15%, #EAEAEA);
  background: -o-linear-gradient(to right, rgba(255, 255, 255, 0), #EAEAEA 15%, #EAEAEA);
  background: -ms-linear-gradient(to right, rgba(255, 255, 255, 0), #EAEAEA 15%, #EAEAEA);
  background: linear-gradient(to right, rgba(255, 255, 255, 0), #EAEAEA 15%, #EAEAEA);
}

/*
 * Item detail styles
 */

.blog-post-detail-container {
  padding-left: 14px;
  padding-right: 14px;
}

.blog-post-detail-date {
  margin-top: 15px;
  font-size: 1.5em;
  font-weight: bold;
}

.blog-post-detail-time {
  font-size: .9em;
}

.blog-post-detail-body {
  white-space: pre-wrap;
  font-size: 1em;
  margin-top: 16px;
}