.add-applet-modal-body {
  height: 320px;
}

.add-applet-icon-container {
  display: inline-block;
  border: 1px solid transparent;
  text-align: center;
  width: 150px;
  padding: 4px;
}

.add-applet-icon-container:hover {
  border: 1px solid lightgray;
}

.add-applet-icon-container-selected {
  background-color: lightgray;
}

.add-applet-icon-container i {
  display: block;
  margin: 0 auto;
}
