.applet-icon {
  width: 40px;
  height: 18px;
  text-align: center;
}

.applet-icon-large {
  font-size: 4em;
  width: 70px;
  height: 70px;
  text-align: center;
}