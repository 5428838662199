/*
 * Styles for the login page
 */

.invite-page-container {
  max-width: 400px;
  margin: 80px auto;
  padding: 15px;
  text-align: center;
}

.invite-page-container h2 {
  color: #21a7f0;
  text-align: center;
}

.invite-page {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  margin: 50px 0 0 0;
  padding: 35px 30px 35px 30px;
  background-color: white;
}

.invite-page h3 {
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 30px;
}

.invite-page button {
  width: 100%;
  margin: 5px 0 0 0;
}

.error-message {
  color: red;
}
