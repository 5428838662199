/*
 * Styles for the login page
 */

 .login-page-container {
    max-width: 400px; 
    margin:80px auto; 
    padding: 15px; 
    text-align: center
}

.login-page-container h2 {
    color: #21A7f0;
    text-align: center;
}

.login-page {
    box-shadow: 0 1px 3px rgba(0,0,0,0.2); 
    margin: 50px 0 0 0; 
    padding: 35px 30px 35px 30px; 
    background-color: white    
}

.login-page h3 {
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 30px;
}

.login-page button {
    width: 100%; 
    margin: 5px 0 0 0
}

div.form-check {
    margin-top: 10px;
    margin-bottom: 10px;
}

label.form-check-label input[type=checkbox]{
    margin-top: .2rem
}

