.app {
  text-align: center;
}

.title-text {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 20px;
}

.brand-tagline {
  padding-bottom: 40px;
}

.informative-text {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 20px;
}

.col-narrow-fixed {
  width: 320px;
}

.btn-narrow-fixed {
  width: 150px;
}

.landing-version {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  color: #eee;
}