.sidebar {
  background-color: #404958;
  color: white;
  border-right: 1px solid #eaeaea;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 240px;
  bottom: 0px;
  padding-top: 12px;
  padding-left: 12px;
  padding-right: 12px;
}

.sidebar-resize {
  position: fixed;
  top: 0px;
  left: 240px;
  width: 16px;
  bottom: 0px;
  border-left: 1px solid transparent;
}

.sidebar-resize:hover {
  border-left: 1px solid blue;
}

.sidebar-collapse {
  position: relative;
  height: 30px;
  top: calc(50% - 15px);
  margin-left: 2px;
  display: none;
}

.sidebar-resize:hover>span {
  color: blue;
  display: inline;
}

.group-selector {
  width: 100%;
  padding-bottom: 24px;
  text-align: initial;
  cursor: pointer;
}

.group-selector img {
  padding-left: 4px;
  padding-right: 4px;
}

.group-selector>#group-name {
  padding-right: 8px;
}

.sidebar-item {
  height: 40px;
  line-height: 40px;
  color: #989898;
  cursor: pointer;
}

.sidebar-item:hover {
  color: white;
}

.sidebar-item-selected {
  font-weight: bold;
  color: white;
}

.sidebar-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  padding-left: 15px;
  padding-bottom: 6px;
  padding-top: 6px;
  border-top: 1px solid #eaeaea;
  width: 100%;
}

.applet-icon {
  margin-top: -1px
}